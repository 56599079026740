// ==============================
// Custom style
// 自定义样式
// ==============================


.video-container {
  width: 100%; /* 或者你想要的任何宽度 */
  padding: 0; /* 确保没有内边距 */
  position: relative; /* 为video设置相对定位 */
  text-align:center;
}

.myvideo {
  width: 90%; /* 视频宽度与父元素一致 */
  height: auto; /* 保持宽高比，高度自动调整 */

}